import api from "../../api/index";

/**
 * 权限状态管理
 *  token
 * 	authUser
 *
 *
 */
export const state = {
  authUser: "", //当前用户
  token: "", // token
  supplierId: "",
};

export const mutations = {
  SET_CURRENT_USER(state, authUser) {
    state.authUser = authUser;
    state.token = authUser.token;
    state.supplierId = authUser.id;

    if (authUser) {
      saveState("auth.authUser", authUser);
      localStorage.setItem("supplierId", authUser.id);
      localStorage.setItem("auth-user", authUser.username);
      localStorage.setItem("auth-token", authUser.token);
      localStorage.setItem("auth-roleId", authUser.roleId);
      localStorage.setItem("supplierActualId",authUser.supplierActualId)
    }
  },
  DEL_CURRENT_USER(state) {
    state.authUser = null;
    deleteState("auth.authUser");
    deleteState("auth-user");
    deleteState("auth-token");
    deleteState("auth-roleId");
    deleteState("supplierId");
    deleteState("supplierActualId");
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.authUser;
  },
};

export const actions = {
  // Logs in the current authUser.
  logIn({ commit }, params = {}) {
    return api.account.login(params).then((res) => {
      if (res.success) {
        const user = res.data;
        commit("SET_CURRENT_USER", user);
      }
      return res;
    });
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    return api.account
      .logout()
      .then((response) => {
        commit("DEL_CURRENT_USER");
      })
      .catch((error) => {
        window.console.log(error);
        commit("DEL_CURRENT_USER");
      });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ username } = {}) {
    return api.account.resetPassword(username).then((response) => {
      const message = response.data;
      return message;
    });
  },
};

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function deleteState(key) {
  window.localStorage.removeItem(key);
}
