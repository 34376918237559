import { format } from "./time";

import qs from "qs";
import axios from "../config/http.js";
import axios1 from "../config/http-json.js";
import baseUrl from "../config/env.js";

async function list(params, ctx) {
  // params.createTime=format(params.createTime)
  return axios.fetchGet(baseUrl + "/smokeBombModel/list", params);
}

function find(params) {
  return axios.fetchGet(baseUrl + "/smokeBombModel/selectByModelId", params);
}

function format_add(params) {
  let d = JSON.parse(JSON.stringify(params));

  d["dose"] = +d["dose"];
  d["maxFrequency"] = +d["maxFrequency"];
  d["medicalCare"] = +d["medicalCare"];
  //d["daySeconds"]=+d["daySeconds"]

  for (let i in d["solvent"]) {
    //   d['solvent'][i]["proportion"]=+d['solvent'][i]["proportion"]
  }

  for (let i in d["formula"]) {
    //  d['formula'][i]["proportion"]=+d['formula'][i]["proportion"]
    d["formula"][i]["status"] = +d["formula"][i]["status"];
  }

  //    let f=""
  //    for (let i in d["flavor"]){
  //        f+=d["flavor"][i]["flavorName"] +","
  //    }
  //    d["flavor"]=f.substr(0,f.length-1)
  //    let form=[]
  //    for (let i in d['formula']){
  //        let s={}
  //        let ff=d['formula'][i]
  //        s["id"]=ff["component"]["id"]
  //        s["component"]=ff["component"]["componentName"]
  //        s["proportion"]=parseInt(ff["proportion"])
  //        s["status"]=parseInt(ff["status"])
  //        form.push(s)
  //    }
  //    d["formula"]=JSON.stringify(form)
  //    let solv=[]
  //    for (let i in d['solvent']){
  //        let s={}
  //        let ff=d['solvent'][i]
  //        s["id"]=ff["solvent"]["id"]
  //        s["solvent"]=ff["solvent"]["solventName"]
  //        s["encoding"]=ff["solvent"]["encoding"]
  //        s["proportion"]=parseInt(ff["proportion"])
  //        solv.push(s)
  //    }
  //    d["solvent"]=JSON.stringify(solv)
  //    d["type"]=parseInt(d["type"]["id"])
  //    d["brandName"]=d["brandName"]["brandName"]
  //    d["atomizingWireType"]=d["atomizingWireType"]["name"]
  // delete d["reportType"]
  return d;
}

function add(params) {
  // let d=format_add(params)
  // return axios1.fetchPost(baseUrl + '/smokeBombModel/add', d);
  return axios1.fetchPost(baseUrl + "/smokeBombModel/add", params);
}

function update(params) {
  // let d=format_add(params)
  // return axios.fetchPost(baseUrl + '/smokeBombModel/updateById', d);
  return axios1.fetchPost(baseUrl + "/smokeBombModel/updateById", params);
}

function equipmentControl(params) {
  return axios.fetchPost(baseUrl + "/smokeBombModel/equipmentControl", params);
}

function absorbedDose(params) {
  return axios.fetchGet(baseUrl + "/smokeBombModel/absorbedDose", params);
}

function associatedWarmUp(params) {
  return axios1.fetchPost(baseUrl + "/smokeBombModel/associatedWarmUp", params);
}

function associatedHeatingMode(params) {
  return axios1.fetchPost(
    baseUrl + "/smokeBombModel/associatedHeatingMode",
    params
  );
}

//下载口味分析二维码
function downModelQrCode(params = {}) {
  let d = JSON.parse(JSON.stringify(params));
  d.id = params.id;
  d["supplier-token"] = localStorage["auth-token"];
  location.href =
    baseUrl + "/smokeBombModel/downModelQrCode" + "?" + qs.stringify(d);
}

function devicesTypeList(params){
  return axios.fetchGet(baseUrl + '/devicesType/list', params);
}
function devicesTypeAdd(params){
  return axios.fetchPost(baseUrl + '/devicesType/add', params);
}
function selectDevicesTypeById(params){
  return axios.fetchGet(baseUrl + '/devicesType/selectDevicesTypeById', params);
}
function devicesTypeUpdate(params){
  return axios.fetchPost(baseUrl + '/devicesType/updateById', params);
}

function productList(params){
  return axios.fetchGet(baseUrl + '/devicesType/productList', params);
}

function productAdd(params){
  return axios1.fetchPost(baseUrl + '/devicesType/saveProduct', params);
}

function productUpdate(params){
  return axios1.fetchPost(baseUrl + '/devicesType/updateProductById', params);
}

function selectAllSpecifications(params){
  return axios.fetchGet(baseUrl + '/dropDown/selectAllSpecifications', params);
}
function selectProductById(params){
  return axios.fetchGet(baseUrl + '/devicesType/selectProductById', params);
}
//启动或禁用设备类型
function enableOrDisableById(params) {
  return axios.fetchPost(baseUrl + "/devicesType/enableOrProhibit",params);
}

function selectBrandProductById(params){
  return axios.fetchGet(baseUrl + '/devicesType/selectBrandProductById', params);
}

export default {
  list,
  find,
  add,
  update,
  equipmentControl,
  absorbedDose,
  associatedWarmUp,
  associatedHeatingMode,
  downModelQrCode,
  devicesTypeList,
  devicesTypeAdd,
  selectDevicesTypeById,
  devicesTypeUpdate,
  enableOrDisableById,
  productList,
  productAdd,
  productUpdate,
  selectAllSpecifications,
  selectProductById,
  selectBrandProductById,
};
