import store from '@/state/store'

export default [
	{
		path: '/',
		meta: {
			authRequired: true,
		},
		name:  store.getters.isIkrusher? 'dashboard':'home',
		component: () => store.getters.isIkrusher? import('./views/dashboard.vue'):import('./views/home')
	},
	//#region 登录退出
	{
		path: '/login',
		name: 'login',
		component: () => import('./views/account/login'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					if (store.getters.isIkrusher()) {
						next({
							name: 'Dashboard',
						})
					} else {
						next({
							name: 'home',
						})
					}
				} else {
					// Continue to the login page
					next()
				}
			},
		},
	},
	{
		path: '/logout',
		name: 'logout',
		meta: {
			authRequired: false,
			beforeResolve(routeTo, routeFrom, next) {
				store.dispatch('auth/logOut')
				next({
					name: 'login',
				})
			},
		},
	},
	//#endregion

	//#region 新手引导
	{
		path: '/newbieGuide',
		name: 'newbieGuide',
		component: () => import('./views/account/newbie-guide.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 驾驶舱
	{
		path: '/cockpit',
		name: 'Cockpit',
		component: () => import('./views/dripicons-mete'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 消费统计报表
	{
		path: '/business',
		name: 'Business',
		component: () => import('./views/business-intelligence'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 统计分析
	{
		path: '/consumptionReport/userAnalysis',
		name: 'userAnalysis',
		component: () => import('./views/consumptionReport/user-analysis.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/consumptionReport/consumptionAnalysis',
		name: 'consumptionAnalysis',
		component: () =>
			import('./views/consumptionReport/consumptio-analysis.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 设备管理
	{
		path: '/deviceMgmt/deviceList',
		name: 'deviceList',
		component: () => import('./views/device-manage/device-list/device.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceInfo',
		name: 'deviceInfo',
		component: () =>
			import('./views/device-manage/device-list/device-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceUsageRecord',
		name: 'deviceUsageRecord',
		component: () => import('./views/device-manage/usage-record.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 空烟弹申请
	{
		path: '/deviceMgmt/orderStatus',
		name: 'orderStatus',
		component: () =>
			import('./views/device-manage/order-status/order-status.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusAdd',
		name: 'orderStatusAdd',
		component: () =>
			import(
				'./views/device-manage/order-status/order-status-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusEdit',
		name: 'orderStatusEdit',
		component: () =>
			import(
				'./views/device-manage/order-status/order-status-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusInfo',
		name: 'orderStatusInfo',
		component: () =>
			import('./views/device-manage/order-status/order-status-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/emptyCartridgeInventory',
		name: 'emptyCartridgeInventory',
		component: () =>
			import(
				'./views/device-manage/empty-cartridge-inventory/empty-cartridge-inventory.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//新增产品模板
	{
		path: '/productionMgmt/productMgmt',
		name: 'productMgmt',
		component: () => import('./views/production-manage/product-mgmt/product.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/productMgmtAdd',
		name: 'productMgmtAdd',
		component: () =>
			import(
				'./views/production-manage/product-mgmt/product-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 设备分期
	{
		path: '/deviceMgmt/deviceLeasing',
		name: 'deviceLeasing',
		component: () =>
			import('./views/device-manage/device-leasing/device-leasing.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceLeasingInfo',
		name: 'deviceLeasingInfo',
		component: () =>
			import(
				'./views/device-manage/device-leasing/device-leasing-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 生产管理
	//#region 空烟弹管理
	{
		path: '/productionMgmt/productionBatchMgmt',
		name: 'productionBatchMgmt',
		component: () =>
			import(
				'./views/production-manage/empty-cartridge-production-orders/index.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/productionBatchMgmtAdd',
		name: 'productionBatchMgmtAdd',
		component: () =>
			import(
				'./views/production-manage/empty-cartridge-production-orders/add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/productionBatchMgmtOmniIDList',
		name: 'productionBatchMgmtOmniIDList',
		component: () =>
			import(
				'./views/production-manage/empty-cartridge-production-orders/product.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/productionBatchMgmtOmniIDInfo',
		name: 'productionBatchMgmtOmniIDInfo',
		component: () =>
			import('./views/production-manage/empty-cartridge-production-orders/product-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#region 烟弹生产管理
	{
		path: '/productionMgmt/cartridgeFilling',
		name: 'CartridgeFilling',
		component: () =>
			import(
				'./views/production-manage/cartridge-filling/cartridge-filling.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/cartridgeFillingInfo',
		name: 'cartridgeFillingInfo',
		component: () =>
			import(
				'./views/production-manage/cartridge-filling/cartridge-filling-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/cartridgeFillingCreate',
		name: 'cartridgeFillingCreate',
		component: () =>
			import(
				'./views/production-manage/cartridge-filling/cartridge-filling-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/cartridgeFillingEdit',
		name: 'cartridgeFillingEdit',
		component: () =>
			import(
				'./views/production-manage/cartridge-filling/cartridge-filling-edit.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 一次性烟弹
	{
		path: '/productionMgmt/disposableFilling',
		name: 'disposableFilling',
		component: () =>
			import(
				'./views/production-manage/disposable-filling/disposable-filling.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/disposableFillingAdd',
		name: 'disposableFillingAdd',
		component: () =>
			import(
				'./views/production-manage/disposable-filling/disposable-filling-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/disposableFillingInfo',
		name: 'disposableFillingInfo',
		component: () =>
			import(
				'./views/production-manage/disposable-filling/disposable-filling-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/disposableFillingEdit',
		name: 'disposableFillingEdit',
		component: () =>
			import(
				'./views/production-manage/disposable-filling/disposable-filling-edit.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 膏弹生产管理
	{
		path: '/productionMgmt/waxCartridge',
		name: 'waxCartridge',
		component: () =>
			import('./views/production-manage/wax-cartridge/wax-cartridge.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxCartridgeAdd',
		name: 'waxCartridgeAdd',
		component: () =>
			import(
				'./views/production-manage/wax-cartridge/wax-cartridge-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxCartridgeInfo',
		name: 'waxCartridgeInfo',
		component: () =>
			import(
				'./views/production-manage/wax-cartridge/wax-cartridge-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 烟油型号(口味)
	{
		path: '/myBrands/flavorMgmt',
		name: 'flavorMgmt',
		component: () => import('./views/my-brands/flavor-manage/flavor.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myBrands/flavorMgmtAdd',
		name: 'flavorMgmtAdd',
		component: () =>
			import(
				'./views/my-brands/flavor-manage/flavor-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myBrands/flavorMgmtEdit',
		name: 'flavorMgmtEdit',
		component: () =>
			import('./views/my-brands/flavor-manage/flavor-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myBrands/flavorMgmtInfo',
		name: 'flavorMgmtInfo',
		component: () =>
			import('./views/my-brands/flavor-manage/flavor-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	//弹出页管理
	{
		path: '/myBrands/manageYourPopup',
		name: 'manageYourPopup',
		component: () =>
			import('./views/my-brands/popup-manage/popup.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 膏类型
	{
		path: '/productionMgmt/waxType',
		name: 'waxType',
		component: () =>
			import('./views/production-manage/wax-type/wax-type.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxTypeAdd',
		name: 'waxTypeAdd',
		component: () =>
			import('./views/production-manage/wax-type/wax-type-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxTypeEdit',
		name: 'waxTypeEdit',
		component: () =>
			import('./views/production-manage/wax-type/wax-type-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 清除烟油批次
	{
		path: '/productionMgmt/removeContentBatch',
		name: 'removeContentBatch',
		component: () =>
			import('./views/production-manage/remove-content-batch/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/removeContentBatchAdd',
		name: 'removeContentBatchAdd',
		component: () =>
			import('./views/production-manage/remove-content-batch/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/removeContentBatchInfo',
		name: 'removeContentBatchInfo',
		component: () =>
			import('./views/production-manage/remove-content-batch/info.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 商品标签管理
	{
		path: '/systemSettings/productGenreTags',
		name: 'productGenreTags',
		component: () =>
			import(
				'./views/settings/product-genre-tags/product-genre-tags.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/productGenreTagsAdd',
		name: 'productGenreTagsAdd',
		component: () =>
			import(
				'./views/settings/product-genre-tags/product-genre-tags-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/productGenreTagsEdit',
		name: 'productGenreTagsEdit',
		component: () =>
			import(
				'./views/settings/product-genre-tags/product-genre-tags-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 使用感受管理
	{
		path: '/systemSettings/usageEffects',
		name: 'usageEffects',
		component: () =>
			import('./views/settings/usage-effects/usage-effects.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/usageEffectsAdd',
		name: 'usageEffectsAdd',
		component: () =>
			import(
				'./views/settings/usage-effects/usage-effects-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/usageEffectsEdit',
		name: 'usageEffectsEdit',
		component: () =>
			import(
				'./views/settings/usage-effects/usage-effects-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 口感描述管理
	{
		path: '/systemSettings/flavorProfiles',
		name: 'flavorProfiles',
		component: () =>
			import(
				'./views/settings/flavor-profiles/flavor-profiles.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/systemSettings/flavorProfilesAdd',
		name: 'flavorProfilesAdd',
		component: () =>
			import(
				'./views/settings/flavor-profiles/flavor-profiles-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/flavorProfilesEdit',
		name: 'flavorProfilesEdit',
		component: () =>
			import(
				'./views/settings/flavor-profiles/flavor-profiles-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/publicMgmt',
		name: 'publicMgmt',
		component: () => import('./views/settings/public-info-mgmt/public-info-mgmt.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 强度标签管理
	{
		path: '/systemSettings/intensity',
		name: 'intensity',
		component: () =>
			import('./views/settings/intensity/intensity.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/intensityAdd',
		name: 'intensityAdd',
		component: () =>
			import('./views/settings/intensity/intensity-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/intensityEdit',
		name: 'intensityEdit',
		component: () =>
			import('./views/settings/intensity/intensity-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 设备型号管理
	{
		path: '/myBrands/curveRecommend',
		name: 'curveRecommend',
		component: () =>
			import('./views/my-curves/curve-recommend/curve-recommend.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 设备预热模式管理
	{
		path: '/myCurves/voltageCurve',
		name: 'voltageCurve',
		component: () =>
			import('./views/my-curves/voltage-curve/voltage-curve.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myCurves/voltageCurveAdd',
		name: 'voltageCurveAdd',
		component: () =>
			import('./views/my-curves/voltage-curve/voltage-curve-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myCurves/voltageCurveEdit',
		name: 'voltageCurveEdit',
		component: () =>
			import('./views/my-curves/voltage-curve/voltage-curve-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#endregion

	//#region 营销管理
	{
		//优惠券-券包
		path: '/marketingMgmt/coupon',
		name: 'coupon',
		component: () => import('./views/marketing-manage/coupon/coupon.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//添加优惠券
		path: '/marketingMgmt/couponAdd',
		name: 'couponAdd',
		component: () =>
			import('./views/marketing-manage/coupon/coupon-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//修改优惠券
		path: '/marketingMgmt/couponEdit',
		name: 'couponEdit',
		component: () =>
			import('./views/marketing-manage/coupon/coupon-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//优惠券列表
		path: '/marketingMgmt/couponList',
		name: 'couponList',
		component: () =>
			import('./views/marketing-manage/coupon/coupon-list.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//分享设置
		path: '/marketingMgmt/shareSetting',
		name: 'shareSetting',
		component: () => import('./views/marketing-manage/share/share.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//分享活动添加
		path: '/marketingMgmt/shareSettingAdd',
		name: 'shareSettingAdd',
		component: () => import('./views/marketing-manage/share/share-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//分享活动修改
		path: '/marketingMgmt/shareSettingEdit',
		name: 'shareSettingEdit',
		component: () => import('./views/marketing-manage/share/share-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//分享奖励列表
		path: '/marketingMgmt/shareRewardList',
		name: 'shareRewardList',
		component: () =>
			import('./views/marketing-manage/share/share-reward-list.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//分享用户列表
		path: '/marketingMgmt/shareList',
		name: 'shareList',
		component: () =>
			import('./views/marketing-manage/share/share-list.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分商品列表
		path: '/marketingMgmt/integralCommodity',
		name: 'integralCommodity',
		component: () =>
			import('./views/marketing-manage/integral/integral-commodity.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分商品添加
		path: '/marketingMgmt/integralCommodityAdd',
		name: 'integralCommodityAdd',
		component: () =>
			import(
				'./views/marketing-manage/integral/integral-commodity-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分商品修改
		path: '/marketingMgmt/integralCommodityEdit',
		name: 'integralCommodityEdit',
		component: () =>
			import(
				'./views/marketing-manage/integral/integral-commodity-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分商品详情
		path: '/marketingMgmt/integralCommodityInfo',
		name: 'integralCommodityInfo',
		component: () =>
			import(
				'./views/marketing-manage/integral/integral-commodity-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分记录（宝石）
		path: '/marketingMgmt/integralRecord',
		name: 'integralRecord',
		component: () =>
			import('./views/marketing-manage/integral/integral-record.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//兑换记录
		path: '/marketingMgmt/exchangeRecord',
		name: 'exchangeRecord',
		component: () =>
			import('./views/marketing-manage/integral/exchange-record.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//兑换记录详情
		path: '/marketingMgmt/exchangeRecordInfo',
		name: 'exchangeRecordInfo',
		component: () =>
			import(
				'./views/marketing-manage/integral/exchange-record-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分活动设置（宝石活动设置）
		path: '/marketingMgmt/integralActivity',
		name: 'integralActivity',
		component: () =>
			import('./views/marketing-manage/integral/integral-activity.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//积分活动详情
		path: '/marketingMgmt/integralActivityInfo',
		name: 'integralActivityInfo',
		component: () =>
			import(
				'./views/marketing-manage/integral/integral-activity-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//添加积分活动
		path: '/marketingMgmt/integralActivityAdd',
		name: 'integralActivityAdd',
		component: () =>
			import(
				'./views/marketing-manage/integral/integral-activity-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//修改积分活动
		path: '/marketingMgmt/integralActivityEdit',
		name: 'integralActivityEdit',
		component: () =>
			import(
				'./views/marketing-manage/integral/integral-activity-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//核销记录
		path: '/marketingMgmt/writeOffRecord',
		name: 'writeOffRecord',
		component: () =>
			import('./views/marketing-manage/write-off/write-off-record.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 开发者测试记录
	{
		path: '/deviceMgmt/developerTestRecord',
		name: 'developerTestRecord',
		component: () =>
			import(
				'./views/production-manage/developer-test-record/developer-test-record.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/developerTestRecordInfo',
		name: 'developerTestRecordInfo',
		component: () =>
			import(
				'./views/production-manage/developer-test-record/developer-test-record-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region User Manage
	{
		path: '/userMgmt/userAccounts',
		name: 'userAccounts',
		component: () => import('./views/user-manage/user-accounts/user.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/userAccountsInfo',
		name: 'userAccountsInfo',
		component: () =>
			import('./views/user-manage/user-accounts/user-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/usageRecord',
		name: 'usageRecord',
		component: () => import('./views/user-manage/usage-record/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/usageRecordInfo',
		name: 'usageRecordInfo',
		component: () => import('./views/user-manage/usage-record/info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/survey',
		name: 'survey',
		component: () => import('./views/user-manage/survey/survey.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/surveyInfo',
		name: 'surveyInfo',
		component: () => import('./views/user-manage/survey/survey-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/UserAuthorization',
		name: 'UserAuthorization',
		component: () =>
			import('./views/user-manage/user-authorization/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/UserAuthorizationInfo',
		name: 'UserAuthorizationInfo',
		component: () =>
			import('./views/user-manage/user-authorization/info.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region Content Manage
	{
		path: '/contentMgmt/article',
		name: 'Article',
		component: () => import('./views/content-manage/article'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/articleAdd',
		name: 'ArticleAdd',
		component: () => import('./views/content-manage/article-add'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region App Manage
	{
		path: '/appManage/faq',
		name: 'FAQ',
		component: () => import('./views/app-manage/faq'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/appManage/faqAdd',
		name: 'FAQAdd',
		component: () => import('./views/app-manage/faq-add'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/appManage/settings',
		name: 'Settings',
		component: () => import('./views/app-manage/settings'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/appManage/feedback',
		name: 'feedback',
		component: () => import('./views/app-manage/feedback'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 医疗管理
	// faq
	{
		path: '/contentMgmt/community',
		name: 'community',
		component: () =>
			import('./views/content-manage/community/community.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/communityCreate',
		name: 'communityCreate',
		component: () =>
			import('./views/content-manage/community/community-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/communityEdit',
		name: 'communityEdit',
		component: () =>
			import('./views/content-manage/community/community-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	// User Guide
	{
		path: '/contentMgmt/userGuide',
		name: 'healthManage/userGuide',
		component: () => import('./views/content-manage/user-guide/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/userGuideAdd',
		name: 'healthManage/userGuideAdd',
		component: () => import('./views/content-manage/user-guide/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/userGuideEdit',
		name: 'healthManage/userGuideEdit',
		component: () => import('./views/content-manage/user-guide/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 调研问题
	{
		path: '/contentMgmt/researchQuestions',
		name: 'researchQuestions',
		component: () =>
			import(
				'./views/content-manage/research-questions/research-questions.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/researchQuestionsAdd',
		name: 'researchQuestionsAdd',
		component: () =>
			import(
				'./views/content-manage/research-questions/research-questions-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/researchQuestionsEdit',
		name: 'researchQuestionsEdit',
		component: () =>
			import(
				'./views/content-manage/research-questions/research-questions-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// Recommender Code
	{
		path: '/contentMgmt/recommenderCode',
		name: 'recommenderCode',
		component: () =>
			import('./views/content-manage/recommender-code/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/recommenderCodeAdd',
		name: 'recommenderCodeAdd',
		component: () =>
			import('./views/content-manage/recommender-code/add.vue'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/contentMgmt/recommenderCodeEdit',
		name: 'healthManage/recommenderCodeEdit',
		component: () =>
			import('./views/content-manage/recommender-code/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 客户管理
	{
		//客户账号管理
		path: '/myBrands/clientMgmt',
		name: 'clientMgmt',
		component: () =>
			import('./views/client-manage/client-account/client-account.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//添加客户账号
		path: '/myBrands/clientMgmtAdd',
		name: 'clientMgmtAdd',
		component: () =>
			import('./views/client-manage/client-account/client-account-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//修改客户账号
		path: '/myBrands/clientMgmtEdit',
		name: 'clientMgmtEdit',
		component: () =>
			import('./views/client-manage/client-account/client-account-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//客户详情
		path: '/myBrands/clientMgmtInfo',
		name: 'clientMgmtInfo',
		component: () =>
			import(
				'./views/client-manage/client-account/client-account-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		//客户类型及权限
		path: '/myBrands/clientType',
		name: 'clientType',
		component: () =>
			import('./views/client-manage/client-type/client-type.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//添加客户类型
		path: '/myBrands/clientTypeAdd',
		name: 'clientTypeAdd',
		component: () =>
			import('./views/client-manage/client-type/client-type-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//修改客户类型
		path: '/myBrands/clientTypeEdit',
		name: 'clientTypeEdit',
		component: () =>
			import('./views/client-manage/client-type/client-type-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//生成验证码
		path: '/consumerMgmt/invitationCodeAdd',
		name: 'invitationCodeAdd',
		component: () =>
			import('./views/client-manage/invitation-code/invitation-code-create.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//验证码列表
		path: '/consumerMgmt/invitationCode',
		name: 'invitationCode',
		component: () =>
			import('./views/client-manage/invitation-code/invitation-code.vue'),
		meta: {
			authRequired: false,
		},
	},

	{
		//温度同步记录
		path: '/allHistories/voltageRecords',
		name: 'voltageRecords',
		component: () => import('./views/all-histories/voltage-records.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//创建品牌记录
		path: '/allHistories/brandRecords',
		name: 'brandRecords',
		component: () => import('./views/all-histories/create-brand-records.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 系统管理--账号管理
	{
		path: '/systemSettings/accounts',
		name: 'accounts',
		component: () => import('./views/settings/accounts/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/accountsAdd',
		name: 'accountsAdd',
		component: () => import('./views/settings/accounts/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/accountsEdit',
		name: '/accountsEdit',
		component: () => import('./views/settings/accounts/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 系统管理--角色管理
	{
		path: '/systemSettings/roleMgmt',
		name: 'roleMgmt',
		component: () => import('./views/settings/role-manage/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/roleMgmtAdd',
		name: 'roleMgmtAdd',
		component: () => import('./views/settings/role-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemSettings/roleMgmtEdit',
		name: 'roleMgmtEdit',
		component: () => import('./views/settings/role-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region data-dictionary 数据字典
	// 加热模式
	{
		path: '/dataDictionary/heatingMode',
		name: 'heatingMode',
		component: () =>
			import('./views/data-dictionary/heating-mode/heating-mode.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/heatingModeAdd',
		name: 'heatingModeAdd',
		component: () =>
			import('./views/data-dictionary/heating-mode/heating-mode-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/heatingModeEdit',
		name: 'heatingModeEdit',
		component: () =>
			import('./views/data-dictionary/heating-mode/heating-mode-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 预热模式
	{
		path: '/dataDictionary/preheatMode',
		name: 'preheatMode',
		component: () =>
			import(
				'./views/data-dictionary/preheating-mode/preheating-mode.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/preheatModeAdd',
		name: 'preheatModeAdd',
		component: () =>
			import(
				'./views/data-dictionary/preheating-mode/preheating-mode-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/preheatModeEdit',
		name: 'preheatModeEdit',
		component: () =>
			import(
				'./views/data-dictionary/preheating-mode/preheating-mode-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 厂家列表
	{
		path: '/dataDictionary/manufacturer',
		name: 'manufacturer',
		component: () =>
			import('./views/data-dictionary/manufacturer/manufacturer.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 厂家添加
	{
		path: '/dataDictionary/manufacturerAdd',
		name: 'manufacturerAdd',
		component: () =>
			import('./views/data-dictionary/manufacturer/manufacturer-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 厂家编辑
	{
		path: '/dataDictionary/manufacturerEdit',
		name: 'manufacturerEdit',
		component: () =>
			import('./views/data-dictionary/manufacturer/manufacturer-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	//品牌管理列表
	{
		path: '/myBrands/brandMgmt',
		name: 'brandMgmt',
		component: () =>
			import(
				'./views/my-brands/brand-manage/consumable-brands.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// 品牌添加
	{
		path: '/myBrands/brandMgmtAdd',
		name: 'brandMgmtAdd',
		component: () =>
			import(
				'./views/my-brands/brand-manage/consumable-brands-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 品牌编辑
	{
		path: '/myBrands/brandMgmtEdit',
		name: 'brandMgmtEdit',
		component: () =>
			import(
				'./views/my-brands/brand-manage/consumable-brands-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 成分列表
	{
		path: '/myBrands/manageYourIngredient',
		name: 'manageYourIngredient',
		component: () =>
			import(
				'./views/my-brands/ingredient-manage/ingredient.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 成分添加
	{
		path: '/myBrands/manageYourIngredientAdd',
		name: 'manageYourIngredientAdd',
		component: () =>
			import(
				'./views/my-brands/ingredient-manage/ingredient-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 成分编辑
	{
		path: '/myBrands/manageYourIngredientEdit',
		name: 'manageYourIngredientEdit',
		component: () =>
			import(
				'./views/my-brands/ingredient-manage/ingredient-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//萜烯
	{
		path: '/myBrands/manageYourTerpenes',
		name: 'manageYourTerpenes',
		component: () =>
			import(
				'./views/my-brands/terpenes-manage/terpenes.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 萜烯添加
	{
		path: '/myBrands/manageYourTerpenesAdd',
		name: 'manageYourTerpenesAdd',
		component: () =>
			import(
				'./views/my-brands/terpenes-manage/terpenes-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 萜烯编辑
	{
		path: '/myBrands/manageYourTerpenesEdit',
		name: 'manageYourTerpenesEdit',
		component: () =>
			import(
				'./views/my-brands/terpenes-manage/terpenes-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	//溶剂列表
	{
		path: '/dataDictionary/solvent',
		name: 'solvent',
		component: () => import('./views/data-dictionary/solvent/solvent.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 溶剂添加
	{
		path: '/dataDictionary/solventAdd',
		name: 'solventAdd',
		component: () =>
			import('./views/data-dictionary/solvent/solventAdd.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 溶剂编辑
	{
		path: '/dataDictionary/solventEdit',
		name: 'solventEdit',
		component: () =>
			import('./views/data-dictionary/solvent/solventAdd.vue'),
		meta: {
			authRequired: false,
		},
	},
	//地区管理列表
	{
		path: '/dataDictionary/regionManage',
		name: 'regionManage',
		component: () =>
			import('./views/data-dictionary/region-manage/region.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 地区添加
	{
		path: '/dataDictionary/regionManageAdd',
		name: 'regionManageAdd',
		component: () =>
			import('./views/data-dictionary/region-manage/regionAdd.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 地区编辑
	{
		path: '/dataDictionary/regionManageEdit',
		name: 'regionManageEdit',
		component: () =>
			import('./views/data-dictionary/region-manage/regionAdd.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion

	//#region 系统维护
	// 系统日志
	{
		path: '/systemMaintenance/systemLog',
		name: 'systemLog',
		component: () =>
			import('./views/system-maintenance/system-log/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 服务器日志
	{
		path: '/systemMaintenance/serverLog',
		name: 'serverLog',
		component: () =>
			import('./views/system-maintenance/server-log/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//二维码列表
		path: '/qrCodeMgmt/qrCode',
		name: 'qrCode',
		component: () =>
			import('./views/qrCode-manage/qrCode/qrCode.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//二维码添加
		path: '/qrCodeMgmt/qrCodeAdd',
		name: 'qrCodeAdd',
		component: () =>
			import('./views/qrCode-manage/qrCode/qrCodeAdd.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//二维码编辑
		path: '/qrCodeMgmt/qrCodeEdit',
		name: 'qrCodeEdit',
		component: () =>
			import('./views/qrCode-manage/qrCode/qrCodeAdd.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//支付订单
		path: '/orderMgmt/payOrder',
		name: 'payOrder',
		component: () =>
			import('./views/order-manage/order/payOrder.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//注册设置
		path: '/marketingMgmt/registerSetting',
		name: 'registerSetting',
		component: () => import('./views/marketing-manage/register/register.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//注册活动添加
		path: '/marketingMgmt/registerSettingAdd',
		name: 'registerSettingAdd',
		component: () => import('./views/marketing-manage/register/register-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//注册活动修改
		path: '/marketingMgmt/registerSettingEdit',
		name: 'registerSettingEdit',
		component: () => import('./views/marketing-manage/register/register-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//找回密码
		path: '/forgotPassword',
		name: 'forgotPassword',
		component: () => import('./views/account/forgot-password.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//找回密码
		path: '/newPassword',
		name: 'newPassword',
		component: () => import('./views/account/new-password.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myBrands/brandMgmtReview',
		name: 'brandMgmtReview',
		component: () => import('./views/my-brands/brand-manage/consumable-brands-review.vue'),
		meta: {
			authRequired: false,
		},
	}, {
		path: '/myBrands/brandMgmtDetails',
		name: 'brandMgmtDetails',
		component: () => import('./views/my-brands/brand-manage/consumable-brands-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//#region 消费统计报表
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('./views/dashboard.vue'),
		meta: {
			authRequired: false,
		},
	},
	//#endregion
	//主题管理
	{
		path: '/myBrands/manageYourSubject',
		name: 'manageYourSubject',
		component: () =>
			import(
				'./views/my-brands/subject-manage/subject.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//添加主题
	{
		path: '/myBrands/manageYourSubjectAdd',
		name: 'manageYourSubjectAdd',
		component: () =>
			import(
				'./views/my-brands/subject-manage/subject-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//编辑主题
	{
		path: '/myBrands/manageYourSubjectEdit',
		name: 'manageYourSubjectEdit',
		component: () =>
			import(
				'./views/my-brands/subject-manage/subject-create.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//客服管理
	{
		path: '/assistantMgmt/assistantRecords',
		name: 'assistantRecords',
		component: () =>
			import(
				'./views/assistant-manage/assistantRecords.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//品牌产品
	{
		path: '/myBrands/brandProductMgmt',
		name: 'brandProductMgmt',
		component: () => import('./views/my-brands/brand-product-manage/brand-product.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/myBrands/brandProductMgmtInfo',
		name: 'brandProductMgmtInfo',
		component: () => import('./views/my-brands/brand-product-manage/brand-product-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		//历史记录
		path: '/myBrands/curvesMgmt',
		name: 'voltageRecords',
		component: () => import('./views/all-histories/voltage-records.vue'),
		meta: {
			authRequired: false,
		},
	},
]
