import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


function selectInvitationCodeList(params){
   return axios.fetchGet(baseUrl + '/invitationCode/list',params)
}

function invitationCodeAdd(params){
    return axios.fetchPost(baseUrl + '/invitationCode/add', params);
}

export default {
    selectInvitationCodeList,
    invitationCodeAdd,
}
